<template>
    <v-card>

        <v-card-title>
            {{ $t('Header') }}
            <v-spacer></v-spacer>
            <v-icon>mdi-page-layout-header</v-icon>
        </v-card-title>

        <v-divider></v-divider>

        <div class="pa-4 d-flex">

            <div>

                <div class="d-flex align-center justify-space-between">

                    <p class="text-subtitle-1">{{ $t("Logo:") }}</p>

                    <v-btn v-if="image.selected" @click="removeImage" icon class="mb-4">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>

                </div>

                <div>
                    <MediaPopup @selected="selectedImage" v-if="!image.selected" />

                    <v-img
                        v-else
                        :src="image.large.public_url"
                        max-width="500"
                    ></v-img>
                </div>
                
            </div>
        </div>

    </v-card>
</template>

<script>
import { ref, inject } from "@vue/composition-api";
import MediaPopup from '@/components/media/Popup';

export default {

    components: {
        MediaPopup
    },

    setup() {

        const app = inject('app');

        const image = ref({
            selected: false,
            large: null
        });

        const selectedImage = (img) => {

            image.value.selected = true;
            image.value.large = img.large;
            app.setSuccess('Image uploaded');

            console.log('Image selected', image.value);
        }

        const removeImage = () => {
            
            image.value.selected = false;
            image.value.large = null;
            app.setSuccess('Image removed');

        }

        return {
            image,
            selectedImage,
            removeImage
        }

    }

}
</script>