<template>
    
    <v-container class="mb-4">

        <h1 class="mt-8">{{ $t('Personalize your website') }}</h1>

        <v-breadcrumbs
        :items="breadcrumbs"
        large class="pl-0 pt-2"
        ></v-breadcrumbs>

        <v-tabs
            v-model="tab"
            :color="app.state.style.primary"
        >
            <v-tabs-slider></v-tabs-slider>

            <v-tab to="/settings/general">
                {{ $t('General') }}
            </v-tab>

            <v-tab to="/settings/languages">
                {{ $t('Languages') }}
            </v-tab>

            <!-- <v-tab href="#booking">
                {{ $t('Booking') }}
            </v-tab> -->

            <v-tab to="/settings/header">
                {{ $t('Header') }}
            </v-tab>

            <v-tab to="/settings/footer">
                {{ $t('Footer') }}
            </v-tab>

            <v-tabs-items v-model="tab">

                <v-tab-item
                    value="/settings/general"
                >
                    <SettingsGeneral />
                </v-tab-item>

                <v-tab-item
                    value="/settings/languages"
                >
                    <SettingsLanguages />
                </v-tab-item>

                <!-- <v-tab-item
                    value="booking"
                >
                    <SettingsBooking class="mt-4" />
                </v-tab-item> -->

                <v-tab-item
                    value="/settings/header"
                >
                    <SettingsHeader />
                </v-tab-item>
                
                <v-tab-item
                    value="/settings/footer"
                >
                    <SettingsFooter />
                </v-tab-item>

            </v-tabs-items>

        </v-tabs>

    </v-container>

</template>

<script>

import { inject, onMounted, ref } from '@vue/composition-api' ;
import SettingsGeneral from '@/components/settings/General' ;
import SettingsLanguages from '@/components/settings/Languages' ;
// import SettingsBooking from '@/components/settings/Booking' ;
import SettingsHeader from '@/components/settings/Header' ;
import SettingsFooter from '@/components/settings/Footer' ;

export default {
    
    components: {
        SettingsGeneral,
        SettingsLanguages,
        // SettingsBooking,
        SettingsHeader,
        SettingsFooter
    },
    setup(props, ctx){
    
        const app = inject('app') ;
        const currentWebsite = inject('currentWebsite') ;

        const tab = ref(null);

        const breadcrumbs = ref([
            {
                text: ctx.root.$t('Dashboard'),
                disabled: false,
                exact: true,
                to: '/dashboard',
            },
            {
                text: ctx.root.$t('Settings'),
                disabled: false,
                exact: true,
                to: '/settings',
            },
        ]) ;

        

        onMounted(() => {
            currentWebsite.setTitle(ctx.root.$t('Settings'));
        });
        
        return {
            app,
            breadcrumbs,
            tab
        }
    
    },

};

</script>