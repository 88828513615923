<template>

    <v-card>

        <v-card-title>
            {{ $t('General') }}
            <v-spacer></v-spacer>
            <v-icon>mdi-home-outline</v-icon>
        </v-card-title>

        <v-divider></v-divider>

        <div class="pa-4">

            <v-text-field
                v-model="title"
                :label="$t('Website title')"
                outlined counter="25"
                persistent-hint
                :hint="$t('In most cases the name of your company. We will use this if no page title is specified on a page.')"
                @keyup="saved = false"
            ></v-text-field>

            <v-text-field
                v-model="currentWebsite.state.data.host"
                :label="$t('Website host')"
                outlined disabled hide-details
            ></v-text-field>

            <v-text-field
                v-model="currentWebsite.state.data.trackings_id"
                :label="$t('Analytics code')"
                outlined hide-details
                @keyup="saved = false"
                class="mt-4"
            ></v-text-field>

            <v-text-field
                v-model="currentWebsite.state.data.gtm_id"
                :label="$t('GTM code')"
                outlined hide-details
                @keyup="saved = false"
                class="mt-4"
            ></v-text-field>

            <v-text-field
                v-model="currentWebsite.state.data.favicon"
                :label="$t('Favicon')"
                outlined hide-details
                @keyup="saved = false"
                class="mt-4"
            ></v-text-field>

            <v-divider class="mt-4"></v-divider>

            <h3 class="mt-4">{{ $t('Multiple administrations') }}</h3>

            <v-switch
                v-model="currentWebsite.state.data.multisite"
                :label="$t('Enable multiple administrations. An administration is a Camping, Hotal or Holiday park. With this function you can make multiple administrations bookable on one website.')"
                hide-details disabled
                @change="saved = false"
            ></v-switch>

            <div class="mt-4">
                
                <div v-if="currentWebsite.state.data.multisite == false">
                    
                    <div class="d-flex align-center">

                        <v-text-field
                            v-model="currentWebsite.state.data.admin_id"
                            :label="$t('Administration ID')"
                            outlined type="number" hide-details disabled
                            style="max-width:200px;"
                            @change="saved = false"
                             class="mr-4"
                        ></v-text-field>

                        <Sync />

                        <v-spacer></v-spacer>

                    </div>
                    

                    <v-text-field
                        v-model="currentWebsite.state.data.public_api_key"
                        :label="$t('Public API key')"
                        outlined hide-details 
                        style="width:500px;"
                        @change="saved = false"
                        class="mt-4"
                    ></v-text-field>
                    
                    
                </div>

                <div v-else>

                    <v-btn
                        :color="app.state.style.primary"
                        outlined to="/accommodations"
                    >
                        {{ $t('Manage administrations') }}
                    </v-btn>

                </div>
                
            </div>

            <v-divider class="mt-4"></v-divider>

            <v-btn
                :color="app.state.style.primary"
                dark depressed
                class="mt-4"
                @click="save()"
                v-if="!saved"
                :loading="loading"
            >
                {{ $t('Save') }}
            </v-btn>

            <div class="mt-4 d-flex align-center" v-else>
                <v-icon left color="green">mdi-check</v-icon>
                {{ $t('Saved') }}
            </div>
            
        </div>            

    </v-card>
    
</template>

<script>
    
import { inject, ref } from '@vue/composition-api' ;
import Sync from '@/components/administrations/Sync' ;

export default {

    components: {
        Sync
    },

    setup(){
    
        const app = inject('app') ;
        const currentWebsite = inject('currentWebsite') ;

        const saved = ref(true) ;
        const loading = ref(false) ;
        
        const title = ref('Unknown') ;
        
        const save = () => {

            loading.value = true ; 

            currentWebsite.state.data.title = title.value ;

            let data = { 
                title: currentWebsite.state.data.title,
                public_api_key: currentWebsite.state.data.public_api_key,
                trackings_id: currentWebsite.state.data.trackings_id,
                gtm_id: currentWebsite.state.data.gtm_id,
                favicon: currentWebsite.state.data.favicon
            } ;
            
            currentWebsite.update(data)
            .then(() => {
                // app.setSuccess(ctx.root.$t('Saved'));
                saved.value = true ;
                loading.value = false ; 
            })
            .catch((error) => {
                app.setError(error);
                loading.value = false ; 
            });

        }

        if(currentWebsite.state.data?.title != null){
            title.value = currentWebsite.state.data.title ;
        }

        return {
            app,
            title,
            save,
            saved,
            loading,
            currentWebsite,
        }
    
    },

};

</script>