<template>
    
        <v-dialog
        v-model="dialog"
        width="500"
        >

            <template v-slot:activator="{ on, attrs }">

                <v-btn
                    :color="app.state.style.primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon left>mdi-autorenew</v-icon>
                    {{ $t('Sync') }}
                </v-btn>

            </template>

            <v-card>

                <div class="pa-4 d-flex align-center">

                    <div>{{ $t('Synchronize') }}</div>

                    <v-spacer></v-spacer>

                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                </div>

                <v-divider></v-divider>

                <div class="pa-4">
                    
                    <v-progress-linear 
                    :color="app.state.style.primary" 
                    :value="percentage"></v-progress-linear>

                </div>

                <div class="pa-4">
                    
                    <span>{{ $t('Administrations found') }}: {{ count }}</span><br>
                    <span>{{ $t('Accommodations imported') }}: {{ countAccommodations }}</span><br>
                    
                </div>

                <v-divider></v-divider>

                <div class="pa-4">

                    <v-btn
                        :color="app.state.style.primary"
                        dark depressed
                        @click="startSync()"
                        :loading="loading"
                    >
                        {{ $t('Synchronize') }}
                    </v-btn>

                    <v-btn
                        :color="app.state.style.primary"
                        outlined class="ml-2"
                        @click="dialog = false"
                    >
                        {{ $t('Cancel') }}
                    </v-btn>

                </div>

            </v-card>
        </v-dialog>
    
</template>

<script>
    
import { inject, ref, computed, watch } from '@vue/composition-api' ;
import firebase from 'firebase' ;

export default {

    setup(props, ctx){
    
        const app = inject('app') ;
        const api = inject('api') ;
        const currentWebsite = inject('currentWebsite') ;
        const currentUser = inject('currentUser') ;
        
        const administrations = ref([]) ;
        const count = ref(null) ;
        const countAccommodations = ref(0) ;
        const dialog = ref(false) ;
        const offset = ref(1) ;
        const limit = ref(1) ;

        const loading = ref(false) ;
        const accoCountries = ref([]) ;
        const productTypes = ref([]) ;
        
        const countAdministrations = () => {

            if(currentWebsite.state.data.multisite == null || currentWebsite.state.data.multisite == false){
                count.value = 1 ;
                return ;
            }
            
            let args = {
                count: 1
            }

            api.get("/administrations", args)
            .then((response) => {
                
                count.value = response.data ;

            })
            .catch(error => {
                app.setError(error);
                return error ;
            });
            
        }

        const get = () => {

            let args = {
                offset: offset.value,
                limit: limit.value,
                get_meta: 1,
                get_age_tables: 1,
            }

            return api.get("/administrations", args) ;
            
        }

        const getSingle = async (adminId) => {

            let args = {
                offset: offset.value,
                limit: limit.value,
                get_meta: 1,
                get_age_tables: 1,
            }

            let admin = await api.get("/administrations/"+adminId, args) ;

            let array = {
                data: [admin.data]
            } ;

            return array ;
            
        }

        const getAccommodations = (adminId) => {

            let args = {
                admin_id: adminId,
                get_meta: 1,
                get_media: 1,
                get_translations: 1,
                get_arrangements: 1,
                get_services: 1,
                limit: 150
            }

            return api.get("/accommodations", args) ;
            
        }

        const startSync = async () => {

            administrations.value = [];
            loading.value = true ;
            
            offset.value = 1 ;
            limit.value = 1 ;

            countAccommodations.value = 0 ;

            sync();

        };

        const sync = async () => {

            try {

                let result = null ;
                
                if(currentWebsite.state.data.multisite == null || currentWebsite.state.data.multisite == false){
                    result = await getSingle(currentWebsite.state.data.admin_id) ;
                }else{

                    if(currentWebsite.state.data.sync_user_uuid != currentUser.uid()){

                        console.error('currentWebsite.state.data.sync_user_uuid', currentWebsite.state.data.sync_user_uuid);
                        console.error('currentUser.uid', currentUser.uid());

                        alert('You have no rights to synchronise these administrations');
                        loading.value = false ;
                        return ;
                        
                    }

                    result = await get() ;

                }

                offset.value = offset.value + limit.value ;

                console.debug('result admins', result);

                if(result.data != null, result.data.length > 0){

                    // get accommodations
                    
                    for (const admin of result.data) {

                        // console.debug(admin, " - ") ;

                        let accommodations = await getAccommodations(admin.id) ;

                        updateAdministration(admin, accommodations.data) ;

                    }
                    
                    administrations.value.push(result.data);

                    if(currentWebsite.state.data.multisite != null && currentWebsite.state.data.multisite == true){
                        sync() ;
                    }else{
                        loading.value = false ;
                        app.setSuccess(ctx.root.$t('Administrations imported'));
                    }
                    
                }else{
                    loading.value = false ;
                    app.setSuccess(ctx.root.$t('Administrations imported'));
                }
                
            } catch (error) {
                app.setError(error);
            }
            
            return ; 

        }

        const percentage = computed(() => {

            if(count.value == null || administrations.value.length == 0){
                return 0 ; 
            }

            return (100 * administrations.value.length) / count.value ;

        });
        
        watch(() => dialog.value, () => {

            if(dialog.value){

                administrations.value = [] ;
                count.value = null ;
                countAccommodations.value = 0 ;
                countAdministrations() ;
                
            }else{
                ctx.emit('close') ;
            }

        })

        const updateAdministration = (data, accommodations) => {

            // remove non-public data from the array
            let allMeta = data.meta ;

            data.meta = {};
            if(allMeta.address != null){ data.meta.address = allMeta.address ; }
            if(allMeta.address_number != null){ data.meta.address_number = allMeta.address_number ; }
            if(allMeta.channel_id != null){ data.meta.channel_id = allMeta.channel_id ; }
            if(allMeta.city != null){ data.meta.city = allMeta.city ; }
            if(allMeta.country != null){ data.meta.country = allMeta.country ; }
            if(allMeta.currency != null){ data.meta.currency = allMeta.currency ; }
            if(allMeta.invoice_logo != null){ data.meta.invoice_logo = allMeta.invoice_logo ; }
            if(allMeta.park_languages != null){ data.meta.park_languages = allMeta.park_languages ; }
            if(allMeta.paytime != null){ data.meta.paytime = allMeta.paytime ; }
            if(allMeta.zipcode != null){ data.meta.zipcode = allMeta.zipcode ; }
            if(allMeta.price_notation != null){ data.meta.price_notation = allMeta.price_notation ; }
            if(allMeta.phone != null){ data.meta.phone = allMeta.phone ; }
            if(allMeta.description != null){ data.meta.description = allMeta.description ; }
            if(allMeta.default_vat != null){ data.meta.default_vat = allMeta.default_vat ; }
            if(allMeta.default_park_language != null){ data.meta.default_park_language = allMeta.default_park_language ; }
            if(allMeta.default_age_table != null){ data.meta.default_age_table = allMeta.default_age_table ; }

            firebase.firestore()
            .collection("websites")
            .doc(String(currentWebsite.id()))
            .collection("administrations")
            .doc(String(data.id))
            .set(data, { merge: true }) ;

            for (const accommodation of accommodations) {
                // console.debug('accommodation', accommodation) ;

                if(accommodation.status == 'active'){

                    countAccommodations.value += 1;

                    if(allMeta.country != null){ accommodation.country = allMeta.country ; }
                    if(allMeta.city != null){ accommodation.city = allMeta.city ; }
                    if(accommodation.meta.product_acco_type != null){ accommodation.product_acco_type = accommodation.meta.product_acco_type ; }

                    firebase.firestore()
                    .collection("websites")
                    .doc(String(currentWebsite.id()))
                    .collection("accommodations")
                    .doc(String(accommodation.id))
                    .set(accommodation, { merge: true }) ;

                    if(accommodation.country != null && !accoCountries.value.includes(accommodation.country)){
                        accoCountries.value.push(accommodation.country) ; 
                    }

                    if(accommodation.product_acco_type != null && !productTypes.value.includes(accommodation.product_acco_type)){
                        productTypes.value.push(accommodation.product_acco_type) ; 
                    }
                    
                }

            }

            firebase.firestore()
            .collection("websites")
            .doc(String(currentWebsite.id()))
            .set({ 
                accommodation_countries: accoCountries.value,
                accommodation_types: productTypes.value,
            }, { merge: true }) ;

        }
                
        return {
            app,
            administrations,
            dialog,
            count,
            get,
            startSync,
            percentage,
            countAccommodations,
            loading
        }
    
    },

};

</script>