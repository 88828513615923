<template>

    <v-card>

        <v-card-title>
            {{ $t('Languages') }}
            <v-spacer></v-spacer>
            <v-icon>mdi-translate</v-icon>
        </v-card-title>

        <v-divider></v-divider>

        <div class="px-4">

            <v-select
                v-model="primary_language"
                :items="app.state.availableLanguages"
                item-text="name"
                item-value="key"
                :label="$t('Primary language')"
                outlined hide-details class="mt-4"
                @change="updatePrimaryLanguage()"
            ></v-select>

            <v-switch
                v-model="multilanguage"
                :label="$t('Enable multilanguage. Countion: All your urls will change from /pagename to /en/pagename with a language parameter in front of every url')"
                hide-details
                @change="saved = false"
            ></v-switch>

        </div>

        <div v-if="multilanguage">

            <v-card-subtitle>
                {{ $t('Select the languages you would like to support') }}
            </v-card-subtitle>

            <div class="pa-2 pt-0 d-flex align-center">

                <v-card class="pa-4 ma-2 text-center"
                v-for="language in app.state.availableLanguages"
                :key="language.key">

                    <v-img  
                    width="50" height="34" class="mx-auto"
                    :src="language.image"></v-img>

                    <div class="pt-2">{{ language.name }}</div>

                    <div class="d-flex align-center justify-center">

                        <v-checkbox 
                        class="ma-0 pa-0 ml-2 mt-2"
                        :input-value="isSelectedLanguage(language.key)"
                        checked @change="toggleLanguage(language)"
                        :disabled="language.key == primary_language"
                        hide-details></v-checkbox>

                    </div>

                </v-card>

            </div>
            
        </div>

        <!-- - book url for each language
        -  -->

        <div class="pa-4">

            <v-divider class="mt-4"></v-divider>

            <v-btn
                :color="app.state.style.primary"
                dark depressed
                class="mt-4"
                @click="save()"
                v-if="!saved"
                :loading="loading"
            >
                {{ $t('Save') }}
            </v-btn>

            <div class="mt-4 d-flex align-center" v-else>
                <v-icon left color="green">mdi-check</v-icon>
                {{ $t('Saved') }}
            </div>
            
        </div>

    </v-card>
    
</template>

<script>
    
import { inject, ref } from '@vue/composition-api' ;

export default {

    setup(){
    
        const app = inject('app') ;
        const currentWebsite = inject('currentWebsite') ;
        const primary_language = ref(currentWebsite.getPrimaryLanguage()) ;
        const multilanguage = ref(false) ;

        if(currentWebsite.state.data?.multilanguage != null){
            multilanguage.value = currentWebsite.state.data.multilanguage ;
        }

        const selected_languages = ref([]) ;

        if(currentWebsite.state.data?.selected_languages != null){
            selected_languages.value = currentWebsite.state.data.selected_languages ;
        }

        const saved = ref(true) ;
        const loading = ref(false) ;

        const save = () => {

            loading.value = true ;
            saved.value = false ;

            if(!isSelectedLanguage(primary_language.value)){
                selected_languages.value.push(primary_language.value) ;
            }
            
            currentWebsite.state.data.primary_language = primary_language.value ;
            currentWebsite.state.data.multilanguage = multilanguage.value ;
            currentWebsite.state.data.selected_languages = selected_languages.value ;

            let data = { 
                primary_language: currentWebsite.state.data.primary_language,
                multilanguage: currentWebsite.state.data.multilanguage,
                selected_languages: currentWebsite.state.data.selected_languages,
                // admin_id: admin_id.value
            } ;
            
            currentWebsite.update(data)
            .then(() => {
                saved.value = true ;
                loading.value = false ;
            })
            .catch((error) => {
                app.setError(error);
                saved.value = true ;
                loading.value = false ;
            });

        }

        const isSelectedLanguage = (languageKey) => {

            if(selected_languages.value.includes(languageKey)){
                return true ;
            }

            return false ;

        }

        const toggleLanguage = (language) => {

            let index = selected_languages.value.indexOf(language.key) ;

            if(index < 0){
                selected_languages.value.push(language.key) ;
            }else{
                selected_languages.value.splice(index, 1) ;
            }

            saved.value = false ;

        }

        const updatePrimaryLanguage = () => {

            if(!isSelectedLanguage(primary_language.value)){
                selected_languages.value.push(primary_language.value) ;
            }

            saved.value = false ;

        }
        
        return {
            app,
            save,
            loading,
            saved,
            primary_language,
            multilanguage,
            isSelectedLanguage,
            toggleLanguage,
            selected_languages,
            updatePrimaryLanguage
        }
    
    },

};

</script>