<template>
    <v-card>

        <v-card-title>
            {{ $t('Footer') }}
            <v-spacer></v-spacer>
            <v-icon>mdi-page-layout-footer</v-icon>
        </v-card-title>

        <v-divider></v-divider>

        <v-container>
            <v-row>
                <v-col>
                    <p class="text-subtitle-1">{{ $t("Logo:") }}</p>
                </v-col>

                <v-col v-if="footerData.logo">
                    <v-row>
                        <v-col cols="auto">
                            <v-btn icon @click="removeLogo()">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>

                        <v-col cols="auto">
                            <v-img :src="footerData.logo.large.public_url" max-width="250"></v-img>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col v-else>
                    <MediaPopup @selected="logoSelected" />
                </v-col>
            </v-row>

            <v-row>
                <v-divider></v-divider>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <p class="text-subtitle-1">{{ $t("Extra information:") }}</p>
                </v-col>
            </v-row>

            <v-row no-gutters>
                <v-col cols="max">
                    <v-textarea
                        v-model="footerData.information.content"
                        @input="footerData.information.saved = false"
                        outlined
                        hide-details
                    ></v-textarea>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="max">
                    <v-btn
                        :color="app.state.style.primary"
                        dark depressed
                        v-if="!footerData.information.saved"
                        @click="footerData.information.saved = true; updateFooter(footerData);"
                    >
                        {{ $t('Save') }}
                    </v-btn>

                    <div class="d-flex align-center" v-else>
                        <v-icon left color="green">mdi-check</v-icon>
                        {{ $t('Saved') }}
                    </div>
                </v-col>
            </v-row>


            <v-row>
                <v-divider></v-divider>
            </v-row>

            <v-row>
                <v-col>
                    <p>{{ $t("Payment methods:") }}</p>
                </v-col>
            </v-row>

            <v-row justify="space-around">
                <v-col
                    v-for="(paymentMethod, i) in footerData.paymentMethods" :key="i"
                    class="d-flex align-center"
                    cols="auto"
                >
                    <v-img
                        :src="paymentMethod.icon"
                        max-width="50"
                        max-height="50"
                        contain
                        class="mr-6"
                    ></v-img>
                    <v-switch
                        v-model="paymentMethod.selected"
                        :color="app.state.style.primary"
                        @change="updateFooter(footerData)"
                    ></v-switch>
                </v-col>
            </v-row>
        </v-container>

    </v-card>
</template>

<script>
import MediaPopup from "@/components/media/Popup";
import { inject, onMounted, ref } from '@vue/composition-api';

export default {
    components: {
        MediaPopup
    },

    setup(props, ctx) {
        const app = inject('app');
        const currentWebsite = inject('currentWebsite');

        const footerData = ref(null);

        onMounted(() => {
            getFooter();
        });

        const getFooter = () => {
            footerData.value = currentWebsite.getFooter();

            if (!footerData.value)
                return setDefaultFooterData();
        }

        const setDefaultFooterData = () => {

            const defaultData = {
                logo: {
                    large: {
                        public_url: "https://storage.googleapis.com/campingcare-static/images/logo-web.png"
                    }
                },

                information: {
                    saved: true,
                    content: `<h4>This is the default <a href="https://camping.care">camping.care</a> footer.</h4><br><p>To change it go to 'Settings -> Footer'</p>`
                },

                paymentMethods: {
                    paypal: {
                        selected: false,
                        icon: "https://reklame2000.nl/wp-content/uploads/2016/10/PayPal-logo-300x296.png"
                    },
                    ideal: {
                        selected: true,
                        icon: "https://storage.googleapis.com/campingcare-websites/apps/rammelbeek/ideal.png"
                    },
                    bank: {
                        selected: true,
                        icon: "https://storage.googleapis.com/campingcare-websites/apps/rammelbeek/banktransfer.png"
                    }
                }
            }

            updateFooter(defaultData);
            
        }

        const updateFooter = async (footerData) => {
            await currentWebsite.update({ footer_data: footerData })
            .then(() => {
                app.setSuccess(ctx.root.$t('Updated footer'));
                getFooter();
            })
            .catch(err => app.setError(err));
        }

        const logoSelected = (img) => {
            footerData.value.logo = img;

            updateFooter(footerData.value);
        }

        const removeLogo = () => {
            footerData.value.logo = null;

            updateFooter(footerData.value);
        }

        return {
            app,
            footerData,
            updateFooter,
            logoSelected,
            removeLogo
        }
    },
}
</script>